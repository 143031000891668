
import { computed, defineComponent, ref, onMounted } from "vue";
import router from "../router/index";

export default defineComponent({
  name: "Application",
  async setup() {
    const application = ref({
      age: null,
      realm: null,
      region: null,
      email: null,
      discordId: null,
      modeOfEngagement: null,
      playTime: null,
      microphoneUse: null,
      reasonForLeaving: null,
      hopesForGuild: null,
      whyIsGuildGoodFit: null,
      whatCanTheyBring: null,
      mythicPlusOpinions: null,
      keyPrepOpinions: null,
      raidTierOpinions: null,
      raidExperience: null,
      pvpExperience: null,
      addonsUsed: null,
      additionalComments: null,
      character: null,
    });

    const loaded = ref(false);
    const snackbar = ref(null);

    const usArray: string[] = [];
    const euArray: string[] = [];

    const usRealms = ref(usArray);
    const euRealms = ref(euArray);

    onMounted(async () => {
      const getRealmsResponse = await fetch("https://memento-api.com/realms", {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        referrerPolicy: 'no-referrer'
      });
      const responseJson = await getRealmsResponse.json();

      const realms: any[] = responseJson.realms;

      const us = realms.filter((realm) => {
        return realm.key.href.indexOf("dynamic-us") !== -1;
      });

      const usNames = us.map((realm) => realm.name);

      usNames.sort((a, b) => a.localeCompare(b));

      usRealms.value = usNames;

      const eu = realms.filter((realm) => {
        return realm.key.href.indexOf("dynamic-eu") !== -1;
      });

      const euNames = eu.map((realm) => realm.name);

      euNames.sort((a, b) => a.localeCompare(b));

      euRealms.value = euNames;
    });

    const nameToSearch = ref(null);

    let searchedOnce = false;

    const noCharacterFound = computed(() => {
      return application.value.character == null && searchedOnce;
    });

    const characterSearch = async function() {
      application.value.character = null;
      if (application.value.region == null || application.value.realm == null) {
        return;
      } else {
        try {
          searchedOnce = true;
          const url = `https://memento-api.com/character/${application.value.region}/${application.value.realm}/${nameToSearch.value}`;
          const getCharacter = await fetch(url, {
            method: "GET",
            mode: "cors",
            credentials: "omit",
          });

          if (getCharacter.status !== 200) {
            throw new Error("Failed to load character");
          }

          const characterData = await getCharacter.json();

          application.value.character = characterData;
        } catch {
          application.value.character = null;
        }
      }
    };

    const snackbarClass = ref("");

    const submitApplication = async function() {
      try {
        const applicationResponse = await fetch(
          "https://memento-api.com/apply",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(application.value),
          }
        );

        if (applicationResponse.status == 201) {
          router.push("/submitted");
        } else {
          throw new Error(
            "We had some trouble creating your application. Please check that all required fields are accounted for."
          );
        }
      } catch {
        snackbarClass.value = "show";
        setTimeout(function() {
          snackbarClass.value = "";
        }, 6000);
      }
    };

    return {
      application,
      loaded,
      usRealms,
      euRealms,
      nameToSearch,
      noCharacterFound,
      characterSearch,
      snackbar,
      snackbarClass,
      submitApplication,
    };
  },
});
