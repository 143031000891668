<template>
  <div class="apply">
    <section class="hero is-medium is-black">
      <div class="hero-body">
        <p class="title has-text-white">
          Guild Application
        </p>
        <p class="subtitle has-text-light">
          We look forward to meeting you!
        </p>
      </div>
    </section>
    <br />
    <Suspense>
        <template #default>
          <application></application>
        </template>
        <template #fallback>
          <div class="section has-text-left">
            <h1 class="title is-4 has-text-white">Loading your application...</h1>
            <p class="is-size-5">
              Give us a moment while we set things up!
            </p>
          </div>
        </template>
    </Suspense>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Application from '../components/Application.vue';

export default defineComponent({
  name: "Apply",
  components: {
    Application
  }
});
</script>
