<template>
  <div>
    <section class="section has-background-white">
      <div class="field">
        <label class="label"
          >Email (<small class="has-text-danger">required</small>)</label
        >
        <input class="input" type="text" v-model="application.email" />
      </div>

      <div class="field">
        <label class="label"
          >Discord ID (<small class="has-text-danger">required</small>)</label
        >
        <input class="input" type="text" v-model="application.discordId" />
      </div>

      <div class="field">
        <label class="label"
          >Age (<small class="has-text-danger">required</small>)</label
        >
        <input class="input" type="number" v-model="application.age" />
      </div>
      <div class="field">
        <label class="label"
          >Region (<small class="has-text-danger">required</small>)</label
        >
        <div class="select">
          <select v-model="application.region">
            <option value="us">United States</option>
            <option value="eu">EU</option>
          </select>
        </div>
      </div>

      <div class="field" v-if="application.region != null">
        <label class="label"
          >Realm (<small class="has-text-danger">required</small>)</label
        >
        <div class="select" v-if="application.region === 'us'">
          <select v-model="application.realm">
            <option
              :value="realm.toLowerCase()"
              v-for="(realm, index) in usRealms"
              :key="index"
              >{{ realm }}</option
            >
          </select>
        </div>
        <div class="select" v-if="application.region === 'eu'">
          <select v-model="application.realm">
            <option
              :value="realm.toLowerCase()"
              v-for="(realm, index) in euRealms"
              :key="index"
              >{{ realm }}</option
            >
          </select>
        </div>
      </div>

      <div
        class="field"
        v-if="application.region != null && application.realm != null"
      >
        <label class="label"
          >Character Search (Please look up your character for this region and
          realm to proceed) (<small class="has-text-danger"
            >search required</small
          >)</label
        >
      </div>

      <div
        class="field has-addons"
        v-if="application.region != null && application.realm != null"
      >
        <div class="control">
          <input class="input" type="text" v-model="nameToSearch" />
        </div>
        <div class="control">
          <button
            class="button"
            :disabled="nameToSearch == null"
            @click="characterSearch"
          >
            Search
          </button>
        </div>
      </div>

      <div
        class="field"
        v-if="application.region != null && application.realm != null"
      >
        <small class="has-text-danger" v-if="noCharacterFound"
          >We could not find your character. Please check that their name is
          correct!</small
        >
      </div>

      <div class="field" v-if="application.character != null">
        <div class="notification">
          <span class="has-text-black">{{
            `${application.character.name} is a ${application.character.spec} ${application.character.wowClass} with an item level of ${application.character.itemLevel}`
          }}</span>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label">How did you hear about us?</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.modeOfEngagement"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >How often do you play? (<small class="has-text-danger"
            >required</small
          >)</label
        >
        <div class="control">
          <textarea class="textarea" v-model="application.playTime"></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >Do you have a working microphone and are you willing to communicate
          through voice chat? (<small class="has-text-danger">required</small
          >)</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.microphoneUse"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >Why did you leave your previous guild (or why do you plan to)?
          (<small class="has-text-danger">required</small>)</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.reasonForLeaving"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >What do you hope to get out of the guild? (<small
            class="has-text-danger"
            >required</small
          >)</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.hopesForGuild"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >What do you think makes Memento Mori a good fit for you?</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.whyIsGuildGoodFit"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >What do you think you can bring to the guild?</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.whatCanTheyBring"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >What are your thoughts on mythic plus this season and the environment
          surrounding it?</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.mythicPlusOpinions"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >What are your thoughts about a situation where in a key, (7 or
          higher) a player admits that they don't know the dungeon or affixes
          that week?</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.keyPrepOpinions"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >What are your opinions on the current raid tier?</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.raidTierOpinions"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >Could you tell us a bit about your raiding experience?</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.raidExperience"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label">Do you participate at all in PVP?</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.pvpExperience"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label">What addons do you use? (in any content)</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.addonsUsed"
          ></textarea>
        </div>
      </div>

      <div class="field" v-if="application.character != null">
        <label class="label"
          >Is there anything else you would like to share?</label
        >
        <div class="control">
          <textarea
            class="textarea"
            v-model="application.additionalComments"
          ></textarea>
        </div>
      </div>

      <div class="field is-grouped" v-if="application.character != null">
        <div class="control">
          <button class="button is-link" @click="submitApplication">
            Submit
          </button>
        </div>
      </div>
    </section>
    <div id="snackbar" ref="snackbar" :class="snackbarClass">
      We had some trouble creating your application. Please check that all
      required fields are accounted for.
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted } from "vue";
import router from "../router/index";

export default defineComponent({
  name: "Application",
  async setup() {
    const application = ref({
      age: null,
      realm: null,
      region: null,
      email: null,
      discordId: null,
      modeOfEngagement: null,
      playTime: null,
      microphoneUse: null,
      reasonForLeaving: null,
      hopesForGuild: null,
      whyIsGuildGoodFit: null,
      whatCanTheyBring: null,
      mythicPlusOpinions: null,
      keyPrepOpinions: null,
      raidTierOpinions: null,
      raidExperience: null,
      pvpExperience: null,
      addonsUsed: null,
      additionalComments: null,
      character: null,
    });

    const loaded = ref(false);
    const snackbar = ref(null);

    const usArray: string[] = [];
    const euArray: string[] = [];

    const usRealms = ref(usArray);
    const euRealms = ref(euArray);

    onMounted(async () => {
      const getRealmsResponse = await fetch("https://memento-api.com/realms", {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        referrerPolicy: 'no-referrer'
      });
      const responseJson = await getRealmsResponse.json();

      const realms: any[] = responseJson.realms;

      const us = realms.filter((realm) => {
        return realm.key.href.indexOf("dynamic-us") !== -1;
      });

      const usNames = us.map((realm) => realm.name);

      usNames.sort((a, b) => a.localeCompare(b));

      usRealms.value = usNames;

      const eu = realms.filter((realm) => {
        return realm.key.href.indexOf("dynamic-eu") !== -1;
      });

      const euNames = eu.map((realm) => realm.name);

      euNames.sort((a, b) => a.localeCompare(b));

      euRealms.value = euNames;
    });

    const nameToSearch = ref(null);

    let searchedOnce = false;

    const noCharacterFound = computed(() => {
      return application.value.character == null && searchedOnce;
    });

    const characterSearch = async function() {
      application.value.character = null;
      if (application.value.region == null || application.value.realm == null) {
        return;
      } else {
        try {
          searchedOnce = true;
          const url = `https://memento-api.com/character/${application.value.region}/${application.value.realm}/${nameToSearch.value}`;
          const getCharacter = await fetch(url, {
            method: "GET",
            mode: "cors",
            credentials: "omit",
          });

          if (getCharacter.status !== 200) {
            throw new Error("Failed to load character");
          }

          const characterData = await getCharacter.json();

          application.value.character = characterData;
        } catch {
          application.value.character = null;
        }
      }
    };

    const snackbarClass = ref("");

    const submitApplication = async function() {
      try {
        const applicationResponse = await fetch(
          "https://memento-api.com/apply",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(application.value),
          }
        );

        if (applicationResponse.status == 201) {
          router.push("/submitted");
        } else {
          throw new Error(
            "We had some trouble creating your application. Please check that all required fields are accounted for."
          );
        }
      } catch {
        snackbarClass.value = "show";
        setTimeout(function() {
          snackbarClass.value = "";
        }, 6000);
      }
    };

    return {
      application,
      loaded,
      usRealms,
      euRealms,
      nameToSearch,
      noCharacterFound,
      characterSearch,
      snackbar,
      snackbarClass,
      submitApplication,
    };
  },
});
</script>
