<template>
  <div class="applications">
    <section class="hero is-medium is-black">
      <div class="hero-body">
        <p class="title has-text-white">
          Guild Applications
        </p>
      </div>
    </section>
    <br />
    <Suspense>
        <template #default>
          <application-table></application-table>
        </template>
        <template #fallback>
          <div class="section has-text-left">
            <h1 class="title is-4 has-text-white">Loading your application...</h1>
            <p class="is-size-5">
              Give us a moment while we set things up!
            </p>
          </div>
        </template>
    </Suspense>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApplicationTable from '../components/ApplicationTable.vue';

export default defineComponent({
  name: "Applications",
  components: {
    ApplicationTable
  }
});
</script>
