<template lang="html">
  <div class="container">
    <table
      class="table is-fullwidth is-bordered is-striped"
      v-if="applications != null"
    >
      <thead>
        <tr>
          <th><abbr title="Applications">Apps</abbr></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in applications" :key="index">
          <div class="section">
            <strong>---Start of Application---</strong>
            <br>
            <br>
            <div class="columns is-multiline">
              <div class="column is-one-quarter">
                <strong>Email:</strong>&nbsp;{{ app.email }}
              </div>
              <div class="column is-one-quarter">
                <strong>Discord:</strong>&nbsp;{{ app.discordId }}
              </div>
              <div class="column is-one-quarter">
                <strong>Age:</strong>&nbsp;{{ app.age }}
              </div>
              <div class="column is-one-quarter">
                <strong>Region:</strong>&nbsp;{{ app.region }}
              </div>
              <div class="column is-one-quarter">
                <strong>Realm:</strong>&nbsp;{{ app.realm }}
              </div>
              <div class="column is-full">
                <div class="columns is-multiline" v-if="app.raiderIO != null">
                  <div class="column is-full">
                    <strong class="title is-4">Raid Progression</strong>
                  </div>
                  <div class="column is-full">
                    <div
                      class="columns"
                      v-for="(raid, index) in app.raiderIO.raidTiers"
                      :key="index"
                    >
                      <div class="column">
                        <strong>Raid:</strong>&nbsp;{{ raid.raid }}
                      </div>
                      <div class="column">
                        <strong>Summary:</strong>&nbsp;{{ raid.summary }}
                      </div>
                      <div class="column">
                        <strong>Norm Kills:</strong>&nbsp;{{
                          raid.normalBossesKilled
                        }}
                      </div>
                      <div class="column">
                        <strong>Heroic Kills:</strong>&nbsp;{{
                          raid.normalBossesKilled
                        }}
                      </div>
                      <div class="column">
                        <strong>Mythic Kills:</strong>&nbsp;{{
                          raid.mythicBossesKilled
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-full">
                <div class="columns is-multiline" v-if="app.raiderIO != null">
                  <div class="column is-full">
                    <strong class="title is-4">Mythic+</strong>
                  </div>
                  <div class="column is-full">
                    <div
                      class="columns"
                      v-for="(mythicScore, index) in app.raiderIO.mythicScores"
                      :key="index"
                    >
                      <div class="column">
                        <strong>Season:</strong>&nbsp;{{ mythicScore.season }}
                      </div>
                      <div class="column">
                        <strong>Overall Score:</strong>&nbsp;{{
                          mythicScore.overallScore
                        }}
                      </div>
                      <div class="column">
                        <strong>DPS Score:</strong>&nbsp;{{
                          mythicScore.dpsScore
                        }}
                      </div>
                      <div class="column">
                        <strong>Healer Score:</strong>&nbsp;{{
                          mythicScore.healerScore
                        }}
                      </div>
                      <div class="column">
                        <strong>Tank Score:</strong>&nbsp;{{
                          mythicScore.tankScore
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-full">
                  <div class="columns is-multiline">
                <div class="column is-full">
                    <strong>How did you hear about us?</strong>
                    <br />
                    <span v-if="app.modeOfEngagement == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.modeOfEngagement }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>How often do you play?</strong>
                    <br />
                    <span v-if="app.playTime == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.playTime }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>Do you have a working microphone and are you willing to communicate through voice chat?</strong>
                    <br />
                    <span v-if="app.microphoneUse == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.microphoneUse }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>Why did you leave your previous guild?</strong>
                    <br />
                    <span v-if="app.reasonForLeaving == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.reasonForLeaving }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>What do you hope to get out of the guild?</strong>
                    <br />
                    <span v-if="app.hopesForGuild == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.hopesForGuild }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>What do you think makes Memento Mori a good fit for you?</strong>
                    <br />
                    <span v-if="app.whyIsGuildGoodFit == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.whyIsGuildGoodFit }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>What do you think you can bring to the guild?</strong>
                    <br />
                    <span v-if="app.whatCanTheyBring == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.whatCanTheyBring }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>What are your thoughts on mythic plus this season and the environment surrounding it?</strong>
                    <br />
                    <span v-if="app.mythicPlusOpinions == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.mythicPlusOpinions }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>What are your thoughts about a situation where in a key, (7 or higher) a player admits that they don't know the dungeon or affixes that week? </strong>
                    <br />
                    <span v-if="app.keyPrepOpinions == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.keyPrepOpinions }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>What are your opinions on the current raid tier?</strong>
                    <br />
                    <span v-if="app.raidTierOpinions == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.raidTierOpinions }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>Could you tell us a bit about your raiding experience?</strong>
                    <br />
                    <div v-if="app.raidExperience == null" class="is-danger"
                      ><em>Omitted</em></div
                    >
                    <div v-else>
                      {{ app.raidExperience }}
                    </div>
                  </div>
                  <div class="column is-full">
                    <strong>Do you participate at all in PVP?</strong>
                    <br />
                    <span v-if="app.pvpExperience == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.pvpExperience }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>What addons do you use? (in any content)</strong>
                    <br />
                    <span v-if="app.addonsUsed == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.addonsUsed }}
                    </span>
                  </div>
                  <div class="column is-full">
                    <strong>Is there anything else you would like to share?</strong>
                    <br />
                    <span v-if="app.additionalComments == null" class="is-danger"
                      ><em>Omitted</em></span
                    >
                    <span v-else>
                      {{ app.additionalComments }}
                    </span>
                    <br>
                    <br>
                    <strong>---End of Application---</strong>
                  </div>
              </div>
              </div>
            </div>
            <br />
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "ApplicationTable",
  async setup() {
    const applications = ref([]);

    onMounted(async () => {
      const getAppsResponse = await fetch("https://memento-api.com/apply", {
        method: "GET",
        mode: "cors",
        credentials: "omit",
      });
      console.log(getAppsResponse);
      const responseJson = await getAppsResponse.json();

      applications.value = responseJson;
    });

    return {
      applications,
    };
  },
});
</script>
