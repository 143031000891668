
import { defineComponent } from "vue";
import Application from '../components/Application.vue';

export default defineComponent({
  name: "Apply",
  components: {
    Application
  }
});
