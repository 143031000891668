
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "ApplicationTable",
  async setup() {
    const applications = ref([]);

    onMounted(async () => {
      const getAppsResponse = await fetch("https://memento-api.com/apply", {
        method: "GET",
        mode: "cors",
        credentials: "omit",
      });
      console.log(getAppsResponse);
      const responseJson = await getAppsResponse.json();

      applications.value = responseJson;
    });

    return {
      applications,
    };
  },
});
