
import { defineComponent } from "vue";
import ApplicationTable from '../components/ApplicationTable.vue';

export default defineComponent({
  name: "Applications",
  components: {
    ApplicationTable
  }
});
